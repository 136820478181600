import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../graphql/fragments"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { HeadingBasic } from "../../components/heading/heading"
import { NavTabs, TabPane } from "../../components/ak-tabs/tabs"
import { Container } from "../../components/grid/grid"
import { CoverMain } from "../../components/cover-main/cover-main"
import { WpGroup } from "../../components/utils/render-functions"

import Document from "../../components/document/document"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const groupBy = (array, key) => {
  if (array) {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue["node"]["acfFile"][key]] =
        result[currentValue["node"]["acfFile"][key]] || []).push(currentValue)
      return result
    }, {})
  }
}

const compareCompanyName = (a, b) => {
  const reportA = a.key
  const reportB = b.key

  let comparison
  if (reportA > reportB) {
    comparison = 1
  } else if (reportA < reportB) {
    comparison = -1
  }

  return comparison
}

const compareByYear = (a, b) => {
  const reportA = a.name
  const reportB = b.name

  let comparison
  if (reportA < reportB) {
    comparison = 1
  } else if (reportA > reportB) {
    comparison = -1
  }

  return comparison
}

const TabContent = ({ data }) => {
  const dataGroupedByCompany = data && groupBy(data, "company")
  const dataArr =
    (dataGroupedByCompany &&
      Object.keys(dataGroupedByCompany).map(key => [
        key,
        dataGroupedByCompany[key],
      ])) ||
    []

  let documentItems = []

  dataArr.length > 0 &&
    dataArr.map((dataItem, i) => {
      let releaseItems = []
      dataItem[1].map(item =>
        releaseItems.push({
          title: item.node.title || "Title",
          linkTo:
            item.node.acfFile.file !== null &&
            item.node.acfFile.file.mediaItemUrl,
          fileSize:
            item.node.acfFile.file !== null && item.node.acfFile.file.filesize,
          fileId:
            item.node.acfFile.file != null && item.node.acfFile.file.databaseId,
          date: `${moment(item.node.date).format("DD MMMM YYYY")} • ${moment(
            item.node.date
          ).format("HH:mm")}`,
        })
      )

      documentItems.push(
        <div key={`${dataItem[0]}-${i}`} className="mb-5">
          <Document
            section={dataItem[0] !== "null" && dataItem[0]}
            data={releaseItems}
          />
        </div>
      )

      return 1
    })

  return (
    <>{documentItems.length > 0 && documentItems.sort(compareCompanyName)}</>
  )
}

const InformationDisclosure = location => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wordPress {
        pageBy(uri: "information-disclosure") {
          title
          slug
          blocks {
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreButtonBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...AcfIconListBlock
            ...CoreGroupBlock
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreButtonBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...AcfIconListBlock
              ...CoreGroupBlock
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
        informationDisclosure: fileCategory(
          id: "informationdisclosure"
          idType: SLUG
        ) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
      }
    }
  `)

  const wordPress = data?.wordPress?.pageBy?.blocks
  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  const informationGroupedByYear =
    data.wordPress.informationDisclosure &&
    data.wordPress.informationDisclosure.filesPdf.edges.length > 0 &&
    groupBy(data.wordPress.informationDisclosure.filesPdf.edges, "year")

  let navTab = []

  const informationArr =
    (informationGroupedByYear &&
      Object.keys(informationGroupedByYear).map(key => [
        key,
        informationGroupedByYear[key],
      ])) ||
    []

  informationArr.map(item => {
    navTab.push({ name: item[0], target: `information${item[0]}` })
    return 1
  })

  return (
    <Layout location={location} currentLocation={location.uri}>
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={data?.wordPress?.pageBy?.title}
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="General Information"
          className="cover-md"
        >
          <Breadcrumbs path={location.uri} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/group":
            return (
              <div key={`core/group-${i}`} className="py-main">
                <WpGroup data={item} />
              </div>
            )
          case "core/paragraph":
            return (
              <section key={`core/paragraph-${i}`} className="py-main">
                <div className="container container-md">
                  <HeadingBasic
                    text={[
                      item.attributes.content ||
                        "We are committed to practicing good corporate<br>governance in accordance with the corporate<br>governance guidelines issued by the Financial Services Authority in every line of our Company’s operations.",
                    ]}
                    className="mb-0"
                  />
                </div>
              </section>
            )
          default:
            return <div key={`empty-${i}`}></div>
        }
      })}

      <Container>
        <NavTabs scrollable data={navTab.sort(compareByYear)} />
      </Container>

      {navTab.map((item, i) => {
        return (
          <TabPane
            key={`information${item.name}-${i}`}
            id={`${item.target}`}
            className={`py-lg-5 pt-5 pt-lg-0  ${i === 0 ? "active" : ""}`}
          >
            <TabContent
              data={
                informationGroupedByYear && informationGroupedByYear[item.name]
              }
            />
          </TabPane>
        )
      })}
    </Layout>
  )
}

export default InformationDisclosure
